
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout } from '../../../actions/AppActions';
import { connect } from 'react-redux';

// users
import moment from 'moment';
import { NAME, URL, URLadmin } from '../../../variables';
import ReactPaginate from 'react-paginate';
import { MdReply } from 'react-icons/md';
import { maskNumber, maskPrice, maskCep, maskCnpj } from '../../../Auxiliar/Masks';
import { FaTrash } from 'react-icons/fa';

class EmpresaDomain extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            loading_screen: false,
            cnpj: '',
            name: '',
            description: '',
            dominios: [],
            loading_save: false,
            msgErro: '',
            msgSuccess: ''
        };
    }



    salvar() {
        // this.setState({ loading_save: false, msgErro: 'Alterações devem ser feitas pelo painel SuperAdmin' });
        // window.scrollTo(500, 0);
        // return
        this.setState({ loading_save: true, msgErro: '', msgSuccess: '' });
        fetch(`${URL}api/store_empresa_admin`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                cnpj: this.state.cnpj,
                name: this.state.name,
                description: this.state.description,
                dominios: this.state.dominios,
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    window.scrollTo(500, 0);

                    this.setState({ loading_save: false, msgErro: erro });
                }
                else {
                    window.scrollTo(500, 0);

                    this.setState({ loading_save: false, redirect: true, msgSuccess: 'Dados da sua Empresa editado com sucesso' });

                }




            } catch (err) {
                console.log(err);
                window.scrollTo(500, 0);

                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                window.scrollTo(500, 0);

                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }


    componentDidMount() {

        this.get_empresa();
    }

    get_empresa() {

        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URLadmin}api/auth/blueen_get_ambiente?connection=${NAME}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                let domains = resp.organization?.domains.map(item => item.domain);

                this.setState({
                    loading_screen: false,
                    cnpj: resp.organization.cnpj,
                    name: resp.organization.commercial_name,
                    description: resp.organization.description,
                    dominios: domains ?? []
                });


            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);
            }
        })
            .catch((err) => {
                this.setState({ redirect: true });
                console.log(err);
            });
    }

    buscar_endereco(cep) {
        this.setState({ loading_cep: true });
        console.log(cep);
        fetch(`https://viacep.com.br/ws/${cep}/json/`, {
            method: 'GET',
            headers: {

            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                this.setState({
                    logradouro: resp.logradouro,
                    bairro: resp.bairro,
                    cidade: resp.localidade,
                    estado: resp.uf,
                    numero: '',
                    loading_cep: false

                }, () => {
                    if (this.state.logradouro == '') {
                        document.getElementById('rua').focus();
                    } else {
                        document.getElementById('numero').focus();
                    }
                });

                // await AsyncStorage.setItem('token', resp.access_token)
                // await AsyncStorage.setItem('user', JSON.stringify(resp.user))




            } catch (err) {
                console.log(err);
                this.setState({ loading: false, msgErro: 'Algo deu errado. Seu CEP está correto?', loading_cep: false });
            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading: false, msgErro: `Erro ao pegar resposta do servidor. Você está conectado a internet?`, loading_cep: false });
            });


    }




    change_tipo(event) {
        this.setState({ tipo: event.target.value });
    }


    render() {
        let anos = [];
        for (let i = parseInt(moment().format('YYYY')); i >= 1990; i--) {
            anos.push(i);
        }
        return (
            <div className="row">

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">


                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Empresa</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Edite a empresa do seu sistema</p>
                            <br />

                            {/* <hr /> */}
                            {this.state.loading_screen == false && <div>
                                {this.state.msgErro != '' && <div>
                                    <div className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.setState({ msgErro: '' })}></button>


                                        <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>

                                    </div>
                                    <br />

                                </div>}
                                {this.state.msgSuccess != '' && <div>
                                    <div className="alert alert-success alert-dismissible fade show mb-0" role="alert">
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.setState({ msgSuccess: '' })}></button>


                                        <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgSuccess}</p>

                                    </div>
                                    <br />

                                </div>}
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">CNPJ </label>
                                            <div className="col-sm-12">
                                                <input  className="form-control" placeholder="CNPJ" type="text" onChange={(e) => this.setState({ cnpj: maskCnpj(e.target.value) })} value={this.state.cnpj} />

                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Razão social </label>
                                            <div className="col-sm-12">
                                                <input  className="form-control" placeholder="Razão social" type="text" onChange={(e) => this.setState({ name: e.target.value })} value={this.state.name} />

                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Descrição </label>
                                            <div className="col-sm-12">
                                                <textarea  className="form-control" placeholder="Descrição" type="text" onChange={(e) => this.setState({ description: e.target.value })} value={this.state.description} ></textarea>

                                            </div>
                                        </div>


                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Domínios </label>
                                            {this.state.dominios.length == 0 &&
                                                <div className="col-sm-12 mt-2" style={{ position: 'relative' }}>
                                                    <input  className="form-control" placeholder="Domínio" type="text" style={{ paddingRight: 35 }} value="" />
                                                </div>
                                            }
                                            {this.state.dominios.map((item, id) => (
                                                <div key={id} className="col-sm-12 mt-2" style={{ position: 'relative' }}>
                                                    <input  className="form-control" placeholder="Domínio" type="text" onChange={(e) => {
                                                        let dominios = [...this.state.dominios];
                                                        dominios[id] = e.target.value;
                                                        this.setState({ dominios: dominios })
                                                    }} style={{ paddingRight: 35 }} value={this.state.dominios[id]} />
                                                    <div style={{ position: 'absolute', right: 25, top: 5 }}>
                                                        <FaTrash onClick={() => {
                                                            let dominios = [...this.state.dominios];
                                                            dominios = dominios.filter((item2, id2) => id2 != id);
                                                            this.setState({ dominios: dominios })

                                                        }} style={{ cursor: 'pointer' }} />
                                                    </div>



                                                </div>
                                            ))}
                                            <div className="col-12 d-flex justify-content-end justify-content-sm-end mt-2"
                                                style={{ marginBottom: '0.5rem' }}>
                                                <button type="button" onClick={() => {
                                                    let dominios = [...this.state.dominios];
                                                    dominios.push('');
                                                    this.setState({ dominios: dominios })

                                                }} className="btn btn-info btn-sm waves-effect waves-light"
                                                    style={{ backgorundColor: '#f2f2f2' }}>Adicionar domínio</button>
                                            </div>
                                        </div>
                                    </div>

                                    {/* {this.state.andamento == true && <AvaliacaoAndamento get_processo={this.get_processo.bind(this)} {...this.props} />} */}
                                </div>

                                {this.state.loading_save == false && <div className="row mt-3">
                                    <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-start"
                                        style={{ marginBottom: '0.5rem' }}>

                                    </div>
                                    <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-end"
                                        style={{ marginBottom: '0.5rem' }}>
                                        <button type="button" onClick={() => this.salvar()} className="btn btn-success btn-lg waves-effect waves-light"
                                            style={{ backgorundColor: '#f2f2f2' }}>Salvar empresa</button>
                                    </div>
                                </div>}
                                {this.state.loading_save == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                    <div className="spinner-border text-primary" role="status"  >
                                    </div>
                                </div></div>}

                                {/* {this.state.andamento == true && <AvaliacaoAndamento get_processo={this.get_processo.bind(this)} {...this.props} />} */}

                            </div>}

                            {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                </div>
                            </div></div>}


                        </div>

                    </div>
                </div>
            </div>
        );
    }


}
const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout })(EmpresaDomain);


